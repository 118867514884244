<template>
  <div class="privacy-policy-wrapper">
    <div class="container">
      <h1>Условия и запреты распространения персональных данных</h1>
      <p>В соответствии со ст. 10.1 Федерального закона от 27.07.2006 N 152-ФЗ “О персональных данных” субъекты
        персональных, давшие согласие на распространение их персональных данных,
        установили следующие условия и запреты:</p>
      <div>
        <table>
          <thead>
          <tr>
            <th>Имя субъекта персональных данных</th>
            <th>Условия и запреты <br>распространения персональных данных, <br>которые установил субъект</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.name">
            <td>{{ user.name }}</td>
            <td>{{ user.text || 'условий и запретов нет' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  setup() {
    const users = [
      {name: 'Елманов Олег Александрович'},
      {name: 'Демченко Людмила Викторовна'},
      {name: 'Киселева Татьяна Сергеевна'},
      {name: 'Круглова Алена Игоревна'},
      {name: 'Лапина Юлия Михайловна'},
      {name: 'Никитина Александра Александровна'},
      {name: 'Осипенко Екатерина Ивановна'},
      {name: 'Хлевная Елена Анатольевна'},
      {name: 'Стерхов Константин Владимирович'},
      {name: 'Сорокин Дмитрий Александрович'},
      {name: 'Каминская Мария Викторовна', text: 'запрет на распространение информации об ученой степени, профессиональных достижениях;\n' +
            'никакие третьи лица не вправе собирать и хранить мои персональные данные\n'},
      {name: 'Васильева Екатерина Владимировна', text: 'разрешаю только доступ к данным (Собирать и обрабатывать данные другие лица не вправе.\n' +
            'Если кто-то возьмет мои данные с сайта и станет их обрабатывать для своих целей, это будет незаконно)\n'},
    ]
    return {
      users
    }
  }
};
</script>


<style scoped lang="scss">
.privacy-policy-wrapper {
  width: 100%;
  padding: 121px 0 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 0;
  max-width: 1170px;
}

th {
  padding: 15px;
}

td {
  text-align: center;
  border: 1px black;
}

tr:nth-child(odd) {
  background-color: #e0dfdf;
}

</style>